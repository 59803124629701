<template>
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu />
      <div class="registry-wrapper">
        <div class="home-registry-wrapper">
          <Template :templateData="cardData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "../../../components/Enterprise/leftmenu/LeftMenu";
import Navbar from "../../../components/header/Navbar.vue";
import Template from "../../../components/admin/enterprise/templates.vue";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "Templates",
  data() {
    return {
      cardData: [],
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  components: {
    Navbar,
    LeftMenu,
    Template,
  },
  methods: {
    ...mapActions("auth", ["getUserData"]),
    getTemplates() {
      this.showLoader = 1;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}enterprise/allEnterpriseTemplates?enterprise_id=${this.enterpriseId}`,
          {}
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.cardData = response.data.data.listing;
            this.showLoader = 0;
          } else if (response.data.statusCode === 404) {
            this.emptyTemplates = response.data.message;
            this.showLoader = 0;
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });
    },
  },
  mounted() {
    const enterpriseId = localStorage.getItem("enterprise_id");
    if (enterpriseId !== null && enterpriseId !== undefined) {
      console.log("enterprise_id from localStorage:", enterpriseId);
      this.enterpriseId = enterpriseId;
      this.getTemplates();
    } else {
      console.log("enterprise_id not found in localStorage");
    }
  }
    


    
  };
  </script>
    <style  scoped>
    .lds-dual-ring-registry {
      position: absolute;
      z-index: 999;
      padding-top: 50% !important;
    }
     </style>