<template>
  <div class="template pb-3">
    <h5 class="title">Templates</h5>
    <div class="col-12 px-0">
      <div v-if="showLoader == 1" class="lds-dual-ring-registry" id="loading"></div>
    </div>
    <div class="container-fluid mt-4" v-if="showLoader == 0">
      <div class="row">
        <!-- Display all the created cards -->
        <div class="templateCard col-lg-3 m-2 position-relative" v-for="(card, index) in templateData" :key="index" @click="viewTemplate(card)">
          <h1 class="text-start">{{ card.template_name }}</h1>
          
          <div class="dropdown position-absolute dropdown-bottom-right">
            <button class="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </button>
            <div class="dropdown-menu p-0 m-0 cur-pointer" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" @click="viewTemplate(card)">Edit</a>
              <!-- <a class="dropdown-item" >Delete</a> -->
            </div>
          </div>
        </div>


        <!-- modal  -->
        <div class="templateCardbtn col-lg-2 m-2">
          <button type="button" class="" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">
            <img src="../../../assets/images/Vector.png" />
          </button>
        </div>
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content template-modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Upload Agreement Template
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form @drop="onFileDrop" @dragover.prevent>
                  <div class="form-group">
                    <div class="drop-zone" id="file-drop-zone" @click="openFileInput">
                      <div class="upload" v-if="!file">
                        <p>Drag and drop a file here or click</p>
                        <img class="pt-4" src="../../../assets/images/upload.png" />
                      </div>
                      <p v-else>{{ file.name }}</p>
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="text" v-model="fileName" placeholder="Enter Agreement name" class="dragInput"
                      id="agreement-name" />
                  </div>
                </form>
                <input type="file" ref="fileInput" style="display: none" @change="onFileChange" />
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="
                  onSave()
                  " data-dismiss="modal">
                  Save
                </button>

                <button type="button" class="btn btn-secondary" data-dismiss="modal"> Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  name: "Template",
  data() {
    return {
      file: null,
      fileName: "",
      foxitData: {},
      // card:[],
      cardData: [],
      showLoader: 0,
    };
  },

  props:{
    templateData: Array
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },

  
  methods: {
    ...mapActions("auth", ["getUserData"]),

    viewTemplate(card){
      setTimeout(() => {
        this.$root.$emit("foxitTemplateView", card.embedded_seesion_url);
      }, 500);
      this.$router.push({ name: "PDFView" });
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    onFileDrop(event) {
      event.preventDefault();
      if (event.dataTransfer.files.length > 0) {
        this.file = event.dataTransfer.files[0];
      }
    },
    onFileChange(event) {
      if (event.target.files.length > 0) {
        this.file = event.target.files[0];
      }
    },
    onSave() {
      // Check if file is provided
      if (!this.file)this.$swal({
        title: "No pdf file found",
        icon: "info",
        showCancelButton: false,
      })

      // Check if agreement name is provided
      else if (!this.fileName.trim()) this.$swal({
        title: "No agreemant name is provided.",
        icon: "info",
        showCancelButton: false,

      })
      else{
        this.sendPdfAndName()
      }


    },
    sendPdfAndName() {
      const currentUrl = window.location.href;
      const enterpriseId = this.user.user.enterprise_id;
      const formData = new FormData();
      formData.append("user_id", this.user.user.id);
      formData.append("enterprise_id", enterpriseId);
      formData.append("template_name", this.fileName);
      formData.append("file", this.file);
      formData.append("redirectUrl", currentUrl);
      formData.append("filename",this.fileName);

      this.showLoader = 1;
      axios
        .post(process.env.VUE_APP_API_URL + "enterprise/uploadDocumentToFoxit", formData)
        .then((response) => {
          if (response.status === 200) {
            this.foxitData = response.data.response.embeddedTemplateSessionURL;
            setTimeout(() => {
              this.$root.$emit("foxitTemplateView", this.foxitData);
              this.showLoader = 1;
            }, 500);
            this.$router.push({ name: "PDFView" });
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });
    },

    // getTemplates() {
    //   this.showLoader = 1;
    //   axios
    //     .get(
    //       `${process.env.VUE_APP_API_URL}enterprise/allEnterpriseTemplates?enterprise_id=${this.enterpriseId}`, {})
    //     .then((response) => {
    //       if (response.data.statusCode === 200) {
    //         this.cardData = response.data.data.listing;
    //         this.showLoader = 0;
    //       }else if (response.data.statusCode === 404) {
    //         this.emptyTemplates = response.data.message;
    //         this.showLoader = 0;
    //     }
    //     })
    //     .catch((error) => {
    //       if (error.response.status === 500) {
    //         alert(error.response.data.message);
    //       }
    //     });
    // }

  },
  // beforeMount() {
  //   this.getUserData();
  // },
  // mounted() {
  //   console.log(this.user)
  //   if(this.user){
  //     this.enterpriseId = this.user.user.enterprise_id;
  //     // console.log("user data" + this.user);
  //     console.log("User enterprise id" + this.enterpriseId);
  //     this.getTemplates();
  //   }
  //   // this.getUserData();
  //   // this.enterpriseId = this.user.user.enterprise_id;
  //   // this.getTemplates();
  // }
};
</script>


<style scoped>
/* #dropdownMenuButton:focus {
  box-shadow: 0px!important;
} */
#dropdownMenuButton:active,
#dropdownMenuButton:focus {
  outline: 0;
  box-shadow: none !important;
}

.lds-dual-ring-registry {
  position: absolute;
  z-index: 999;
  padding-top: 50% !important;
}
.drop-zone {
  height: 150px;
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  background-color: #ccc;
}

.drop-zone p {
  margin: 0;
}

.dragInput {
  width: 100%;
  border: none;
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

.dragInput:focus {
  outline: none;
}

.dropdown-bottom-right {
  padding-bottom: 6px;
  bottom: 0;
  right: 0;
}
.dropdown-menu {
  background-color: var(--white);
  -webkit-border-radius: 15px!important;
  -moz-border-radius: 15px!important;
  -ms-border-radius: 15px!important;
  -o-border-radius: 15px!important;
  border-radius: 15px!important;
  min-width: 5rem;
  position: fixed;
  z-index: 9999;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
  width: auto;
  padding: 7px 15px;
  transform: translate3d(-63px, 29px, 0px)!important;

}
.dropdown-menu a:focus {
  border-radius: 15px!important;
}
.dropdown-menu .dropdown-item  {
  cursor: pointer;
  margin: 7px 0;
  color: var(--main-black);
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: block;
  padding: 0.25rem 0.5rem!important;

}
.dropdown-menu .dropdown-item:hover {
  color: var(--main-black);
  background-color: inherit;
  border-radius: 15px!important;

}
.template-modal-content{
  color: #6c757d;
  }
</style>